// SettingsVehicleList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as vehicleService from "../../services/vehicleService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { infiniteListPageSize } from "../../models/common/consts";
import { IVehicleEdit } from "../../models/vehicle/vehicleEdit";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { SettingsVehicleDialog } from "./settingsVehicleDialog";
import { handleApiError } from "../../models/store/storeEffects";
import { CheckBox } from "../framework/checkbox";
import { VehicleOperations } from "../../models/vehicle/vehicleOperations";
import { AvatarImage } from "../framework/avatarImage";

// SettingsVehicleListLineHeader
export interface ISettingsVehicleListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SettingsVehicleListLineHeader extends React.Component<ISettingsVehicleListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title="#"
                    column="number"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.RegisterNumber}
                    column="registerNumber"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Brand}
                    column="brand"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.VehicleGroup}
                    column="vehicleGroup"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Comment}
                    column="comment"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.NextVehicleInspection}
                    column="inspectionDate"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.InUse}
                    column="activeState"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// SettingsVehicleListLine
export interface ISettingsVehicleListLineProp {
    item: IVehicleItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsVehicleListLine extends React.Component<ISettingsVehicleListLineProp, {}> {
    handleNumberClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "") + (item.activeState > 0 ? "" : " disabled")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-1 number ca" onClick={this.handleNumberClick}>{item.number.toString(10)}</div>
                <div className="col-2 withAvatar">
                    <AvatarImage
                        classes="left oval"
                        image={item.picture}
                        text={item.getAvatarText()}
                        fullText={true}
                        name={item.registerNumber}
                        tooltip={item.registerNumber}
                        showName={true}
                    />
                </div>
                <div className="col-2">{item.brand}</div>
                <div className="col-2">{item.vehicleGroup}</div>
                <div className="col-2">{item.comment}</div>
                <div className="col-2">{!Base.isNullOrUndefined(item.inspectionDate) ? Base.utcTimeToDateStr(item.inspectionDate) : ""}</div>
                <div className="col-1 inUse ca">{item.activeState > 0 ? <div className="icon check" /> : ""}</div>
            </div>
        );
    }
}

// SettingsVehicleList
export interface ISettingsVehicleListProp {
}

interface ISettingsVehicleListState {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    showNotInUse: boolean;
    items: IVehicleItem[];

    // Edit
    selectedId: string;
    editItem: IVehicleEdit;
    showEditDialog: boolean;
}

export class SettingsVehicleList extends React.Component<ISettingsVehicleListProp, ISettingsVehicleListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "number", sortOrderIsAsc: true, showNotInUse: false, items: [], selectedId: null, editItem: null, showEditDialog: false
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        const state = this.state;
        obj.isLoading = true;
        store.customStore.dispatch(StoreActions.fetchStart());
        vehicleService.getVehicleItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc, showNotInUse)
            .then(dbItems => {
                const listItems = Base.getListItems<IVehicleItem>(state.items, state.selectedId, [], dbItems.items, resetItems, refreshList);
                obj.setState({
                    page: !refreshList ? dbItems.page : page,
                    hasMore: dbItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    showNotInUse: showNotInUse,
                    items: listItems.items,
                    selectedId: listItems.selectedId
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.isLoading = false;
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, false, true);
    };

    handleScrollSub = Base.debounce((obj: SettingsVehicleList) => {
        if (obj.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, this.state.showNotInUse, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(this.state.pageSize, 1, filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(this.state.pageSize, 1, this.state.filter, sortColumn, sortOrderIsAsc, this.state.showNotInUse, true, false);
    };

    changeShowNotInUse = (showNotInUse: boolean) => {
        if (showNotInUse === this.state.showNotInUse) return;
        this.searchItems(this.state.pageSize, 1, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, showNotInUse, true, false);
    };

    getEditItem = (id: string) => {
        const obj = this;
        VehicleOperations.getVehicleEdit(id)
            .then(editItem => {
                obj.setState({
                    showEditDialog: true,
                    editItem: editItem
                });
            });
    };

    handleAdd = () => {
        this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = () => {
        this.setState({
            showEditDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleRemove = () => {
        const obj = this;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const item = this.state.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(item)) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.VehicleRemoveConfirmation, item.number), () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(StoreActions.fetchStart());
            vehicleService.removeVehicle(selectedId)
                .then(success => {
                    store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                    obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
                })
                .catch(error => {
                    store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
        }));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const state = this.state;
        const items = state.items;
        return (
            <div className="backgroundContent">
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.Vehicles}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                    </div>
                    <div className="col-auto right">
                        <CheckBox
                            classes="tool"
                            title={Translations.ShowNotInUse}
                            enabled={true}
                            checked={state.showNotInUse}
                            onCheckboxClickBoolean={(checked: boolean) => { this.changeShowNotInUse(checked); }}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SettingsVehicleListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer main vehiclesContainer" ref={(elem) => { this.containerDiv = elem; }}>
                    <div className="list vehicles striped">
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SettingsVehicleListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {state.showEditDialog &&
                    <SettingsVehicleDialog
                        editItem={state.editItem}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}
