export interface IDayBookingTypeEditItem {
    id: string;
    salaryRowTypeId: string;
    number: number;
    name: string;
    rowId: string;
    calculateNormal: boolean;
    calculateOvertime: boolean;
    calculateFillingHours: boolean;
    dayHoursRatio: number;

    isNew():boolean;
}

export class DayBookingTypeEditItem implements IDayBookingTypeEditItem {
    id: string;
    salaryRowTypeId: string;
    number: number;
    name: string;
    rowId: string;
    calculateNormal: boolean;
    calculateOvertime: boolean;
    calculateFillingHours: boolean;
    dayHoursRatio: number;

    constructor();
    constructor(obj: IDayBookingTypeEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.rowId = obj && obj.rowId || "";
        this.calculateNormal = obj && obj.calculateNormal;
        this.calculateOvertime = obj && obj.calculateOvertime;
        this.calculateFillingHours = obj && obj.calculateFillingHours;
        this.dayHoursRatio = obj && obj.dayHoursRatio || 1;
    }

    isNew():boolean {
        return !this.rowId;
    }
}
