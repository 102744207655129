import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Checkbox, Typography } from "@mui/material";
import {
    IBoundaries,
    OvertimeDailyOption,
    OvertimePeriodOption,
    TESCalculationParameters,
} from "../../models/calculation/TESCalculationParameters";
import { CalcBoundaries } from "./CalcBoundaries";
import MuiSelect from "../framework/muiSelect";
import { NumberInput } from "../framework/numberInput";

interface CalcOvertimesProps {
    translate: (str: string) => string;
    handleChange: (attr: string, value: any) => void;
    settings: TESCalculationParameters;
    setSettings: (
        value: React.SetStateAction<TESCalculationParameters>
    ) => void;
}

export const CalcOvertimes = ({
    translate,
    handleChange,
    settings,
    setSettings,
}: CalcOvertimesProps) => {
    const {
        overtimePeriodBoundaries,
        overtimeWeekBoundaries,
        isMonthlyOvertimeCalculationOn,
        overtimePeriodOption,
        isWeeklyOvertimeCalculationOn,
        isOvertimeCalculationOn,
        isDailyOvertimeCalculationOn,
        isSaturdayOvertimeCalculationOn,
        overtimeDailyBoundaries,
        overtimeSaturdayBoundaries,
        midweekHolidayOvertimeLimitDeduction,
    } = settings || {};

    const fillBoundaries = (
        boundaries: IBoundaries[],
        option: OvertimePeriodOption
    ) => {
        const newSettings = { ...settings };

        if (option === OvertimePeriodOption.Week) {
            newSettings.overtimeWeekBoundaries = boundaries;
            newSettings.isWeeklyOvertimeCalculationOn = boundaries.length > 0;
        } else {
            newSettings.overtimePeriodBoundaries = boundaries;
            newSettings.isOvertimeCalculationOn = boundaries.length > 0;
        }

        setSettings(newSettings);
    };

    const fillDailyBoundaries = (boundaries: IBoundaries[], option: OvertimeDailyOption) => {
        const newSettings = { ...settings };

        if (option === OvertimeDailyOption.Daily) {
            newSettings.overtimeDailyBoundaries = boundaries;
            newSettings.isDailyOvertimeCalculationOn = boundaries.length > 0;

            if (!newSettings.isDailyOvertimeCalculationOn) {
                // Disable saturday overtime, if daily overtime not in use.
                newSettings.isSaturdayOvertimeCalculationOn = false;
                newSettings.overtimeSaturdayBoundaries = [];
            }
        } else if (option === OvertimeDailyOption.Saturday) {
            newSettings.overtimeSaturdayBoundaries = boundaries;
            newSettings.isSaturdayOvertimeCalculationOn = boundaries.length > 0;
        }

        setSettings(newSettings);
    }

    const handleOvertimePeriodChange = (val: OvertimePeriodOption) => {

        const newSettings = { ...settings };

        if (val === OvertimePeriodOption.Month || val === OvertimePeriodOption.Period) {
            newSettings.overtimeDailyBoundaries = [];
            newSettings.isDailyOvertimeCalculationOn = false;
            newSettings.overtimeSaturdayBoundaries = [];
            newSettings.isSaturdayOvertimeCalculationOn = false;
        }

        if (val === OvertimePeriodOption.Month || val === OvertimePeriodOption.None) {
            newSettings.midweekHolidayOvertimeLimitDeduction = 0;
        }

        newSettings.overtimePeriodOption = val;
        newSettings.isOvertimeCalculationOn = val === OvertimePeriodOption.Period;
        newSettings.overtimePeriodBoundaries = val === OvertimePeriodOption.Period ? settings.overtimePeriodBoundaries : [];
        newSettings.isMonthlyOvertimeCalculationOn = val === OvertimePeriodOption.Month;
        newSettings.isWeeklyOvertimeCalculationOn = val === OvertimePeriodOption.Week;
        newSettings.overtimeWeekBoundaries = val === OvertimePeriodOption.Week ? settings.overtimeWeekBoundaries : [];

        setSettings(newSettings);
    };

    return (
        <>
            <Grid2 container direction="column" spacing={2} p={3}>
                <Grid2>
                    <Typography variant="h3">
                        {translate("periodOvertimes")}
                    </Typography>
                </Grid2>
                <Grid2>
                    <MuiSelect
                        fullWidth={false}
                        className="field-width-normal"
                        value={overtimePeriodOption}
                        onChange={handleOvertimePeriodChange}
                        options={Object.keys(OvertimePeriodOption).map((o) => ({
                            value: OvertimePeriodOption[o],
                            label: translate(
                                `overtimePeriodOption.${OvertimePeriodOption[o]}`
                            ),
                        }))}
                    />
                </Grid2>
                <Grid2>
                    {overtimePeriodOption === OvertimePeriodOption.Period ? (
                        <CalcBoundaries
                            isOn={isOvertimeCalculationOn}
                            boundaries={overtimePeriodBoundaries}
                            translate={translate}
                            fillBoundaries={(boundaries) =>
                                fillBoundaries(boundaries, overtimePeriodOption)
                            }
                            default100={92}
                            default50={80}
                            disabled={false}
                        />
                    ) : null}
                    {overtimePeriodOption === OvertimePeriodOption.Month ? (
                        <>
                            <label className={"field-width-normal"}>
                                <Checkbox
                                    id="MonthlyOvertimeCalculation"
                                    checked={isMonthlyOvertimeCalculationOn}
                                    onChange={(event) =>
                                        handleChange(
                                            "isMonthlyOvertimeCalculationOn",
                                            event.target.checked
                                        )
                                    }
                                />
                                {translate("MonthlyOvertimeCalculation")}
                            </label>
                        </>
                    ) : null}
                    {overtimePeriodOption === OvertimePeriodOption.Week ? (
                        <CalcBoundaries
                            isOn={isWeeklyOvertimeCalculationOn}
                            boundaries={overtimeWeekBoundaries}
                            translate={translate}
                            fillBoundaries={(boundaries) =>
                                fillBoundaries(boundaries, overtimePeriodOption)
                            }
                            default100={48}
                            default50={40}
                            disabled={false}
                        />
                    ) : null}
                    {[
                        OvertimePeriodOption.Period,
                        OvertimePeriodOption.Week,
                    ].includes(overtimePeriodOption) ? (
                        <NumberInput
                            id="midweekHolidayOvertimeLimitDeduction"
                            value={midweekHolidayOvertimeLimitDeduction}
                            className="field-width-normal"
                            label={translate(
                                "midweekHolidayOvertimeLimitDeduction"
                            )}
                            onChange={(val) =>
                                handleChange(
                                    "midweekHolidayOvertimeLimitDeduction",
                                    val
                                )
                            }
                            endAdornment="min"
                            sx={{ mt: 3 }}
                        />
                    ) : null}
                </Grid2>
            </Grid2>
            {isOvertimeCalculationOn ||
            isMonthlyOvertimeCalculationOn ? null : (
                <Grid2 container direction="column" spacing={2} p={3}>
                    <Grid2>
                        <Typography variant="h3">
                            {translate("dailyOvertime")}
                        </Typography>
                        <CalcBoundaries
                            isOn={isDailyOvertimeCalculationOn}
                            boundaries={overtimeDailyBoundaries}
                            translate={translate}
                            fillBoundaries={(boundaries) =>
                                fillDailyBoundaries(
                                    boundaries,
                                    OvertimeDailyOption.Daily
                                )
                            }
                            default50={8}
                            default100={10}
                        />
                        {isDailyOvertimeCalculationOn ? (
                            <>
                                <Typography className={"mt-4"} variant="h3">
                                    {translate("dailySaturdayOvertime")}
                                </Typography>
                                <CalcBoundaries
                                    isOn={isSaturdayOvertimeCalculationOn}
                                    boundaries={overtimeSaturdayBoundaries}
                                    translate={translate}
                                    fillBoundaries={(boundaries) =>
                                        fillDailyBoundaries(
                                            boundaries,
                                            OvertimeDailyOption.Saturday
                                        )
                                    }
                                    default50={0}
                                    default100={8}
                                />
                            </>
                        ) : null}
                    </Grid2>
                </Grid2>
            )}
        </>
    );
};
