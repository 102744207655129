import * as React from "react";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import { IVehicleGroupEdit } from "../../models/vehicleGroup/vehicleGroupEdit";
import * as vehicleGroupService from "../../services/vehicleGroupService";
import { VehicleItem } from "../../models/vehicle/vehicleItem";

export interface ISettingsVehicleGroupDialogProps {
    classes?: string;
    editItem: IVehicleGroupEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsVehicleGroupDialogState {
    vehicles: VehicleItem[];
    vehicleGroupId: string;
    name: string;
    description: string;
}

export class SettingsVehicleGroupDialog extends React.Component<ISettingsVehicleGroupDialogProps, ISettingsVehicleGroupDialogState> {
    private static orgStateHash: string = "";

    constructor(props: ISettingsVehicleGroupDialogProps) {
        super(props);
        const vehicleGroup = props.editItem.vehicleGroup;
        const vehicles = props.editItem.vehicles;
        this.state = {
            vehicles: vehicles,
            vehicleGroupId: vehicleGroup.id,
            name: vehicleGroup.name,
            description: vehicleGroup.description,
        };
        const saveData = SettingsVehicleGroupDialog.getSaveDataFromState(props, this.state);
        SettingsVehicleGroupDialog.orgStateHash = saveData.hash;
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;

        if (name === "nm") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        }
    };

    private static validate = (state: ISettingsVehicleGroupDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsVehicleGroupDialogProps, state: ISettingsVehicleGroupDialogState): SaveData => {
        const data = new SaveData();
        const vehicleGroup = props.editItem.vehicleGroup;

        data.append("id", vehicleGroup.id);
        data.append("rowId", vehicleGroup.rowId);
        data.append("name", state.name);
        data.append("description", state.description);

        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsVehicleGroupDialog.validate(this.state)) return;

        const saveData = SettingsVehicleGroupDialog.getSaveDataFromState(this.props, this.state);
        if (!saveData) return;

        store.customStore.dispatch(storeActions.fetchStart());
        vehicleGroupService.saveVehicleGroupEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsVehicleGroupDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsVehicleGroupDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "settings VehicleGroup px800" + (props.classes ? " " + props.classes : "");

        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.VehicleGroup}
                    show={true}
                    body={
                        <div className="">
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.Name}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="nm"
                                                title={Translations.Name}
                                                value={state.name}
                                                onChange={this.handleChange}
                                                maxLength={50}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Description}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                title={Translations.Description}
                                                value={state.description}
                                                onChange={this.handleChange}
                                                maxLength={1000}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
