import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { VehicleGroupItems } from "../models/vehicleGroup/vehicleGroupItems";
import { VehicleGroupEdit } from "../models/vehicleGroup/vehicleGroupEdit";
import { ApiSuccess, SaveSuccess } from "./baseService";

export const getVehicleGroupItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<VehicleGroupItems> => {
    return getApiCall<VehicleGroupItems>("api/vehiclegroup/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), VehicleGroupItems);
};

export const getVehicleGroupEdit = (id: string): Promise<VehicleGroupEdit> => {
    return getApiCall<VehicleGroupEdit>("api/vehiclegroup/edit/?id=" + (id || ""), VehicleGroupEdit);
};

export const saveVehicleGroupEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/vehiclegroup/save", data, SaveSuccess);
};

export const removeVehicleGroup = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/vehiclegroup/remove/?id=" + (id || ""), ApiSuccess);
};
