import { IEditItem, EditItem } from "../common/editItem";
import { IWorkShiftTimeSlotType } from "../workShiftTimeSlotType/workShiftTimeSlotType";
import { Base } from "../../framework/base";

export interface IWorkOrderTypeEditItemWorkShiftTimeSlotType extends IEditItem {
    workShiftTimeSlotTypeId: string;
    productId: string;

    sortValue: number;

    setSortValue(workShiftTimeSlotTypes: IWorkShiftTimeSlotType[]);
}

export class WorkOrderTypeEditItemWorkShiftTimeSlotType extends EditItem implements IWorkOrderTypeEditItemWorkShiftTimeSlotType {
    workShiftTimeSlotTypeId: string;
    productId: string;

    sortValue: number;

    constructor();
    constructor(obj: IWorkOrderTypeEditItemWorkShiftTimeSlotType);
    constructor(obj?: any) {
        super(obj);
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.productId = obj && obj.productId || "";
        this.sortValue = obj && obj.sortValue || 0;
    }

    setSortValue = (workShiftTimeSlotTypes: IWorkShiftTimeSlotType[]) => {
        const workShiftTimeSlotType = workShiftTimeSlotTypes.find(i => i.id === this.workShiftTimeSlotTypeId);
        if (!workShiftTimeSlotType) return;
        this.sortValue = workShiftTimeSlotType.category;
    }

    static sortWorkOrderTypeEditItemWorkShiftTimeSlotTypes(items: IWorkOrderTypeEditItemWorkShiftTimeSlotType[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IWorkOrderTypeEditItemWorkShiftTimeSlotType, b: IWorkOrderTypeEditItemWorkShiftTimeSlotType) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : Base.numberCompare(a.sortValue, b.sortValue); });
    }
}

export class WorkOrderTypeEditItemSaveWorkShiftTimeSlotType extends EditItem {
    workShiftTimeSlotTypeId: string;
    productId: string;

    constructor();
    constructor(obj: IWorkOrderTypeEditItemWorkShiftTimeSlotType);
    constructor(obj?: any) {
        super(obj);
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.productId = obj && obj.productId || null;
    }
}
