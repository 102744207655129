import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Checkbox, Typography } from "@mui/material";
import { TESCalculationParameters } from "../../models/calculation/TESCalculationParameters";
import { NumberInput } from "../framework/numberInput";

interface CalcFillingHoursProps {
    translate: (str: string) => string;
    handleChange: (attr: string, value: any) => void;
    settings: TESCalculationParameters;
}

export const CalcFillingHours = ({
    translate,
    handleChange,
    settings,
}: CalcFillingHoursProps) => {
    const {
        isFillingHourCalculationOn,
        fillingHourCalculationLimit,
    } = settings || {};

    return (
        <>
            <Grid2 container direction="column" spacing={2} p={3}>
                <Grid2>
                    <Typography variant="h3">
                        {translate("fillingHours")}
                    </Typography>
                    <Grid2
                        container
                        className="field-width-normal"
                        direction="column"
                        spacing={2}
                        p={1}
                    >
                        <Grid2>
                            <label>
                                <Checkbox
                                    id="FillingHours"
                                    checked={settings.isFillingHourCalculationOn}
                                    onChange={(event) =>
                                        handleChange(
                                            "isFillingHourCalculationOn",
                                            event.target.checked
                                        )
                                    }
                                />
                                {translate("fillingHoursOn")}
                            </label>
                        </Grid2>
                        <Grid2 width={"180px"}>
                            <NumberInput
                                id="FillingHoursLimit"
                                value={fillingHourCalculationLimit}
                                label={translate("fillingHoursLimit")}
                                onChange={(val) =>
                                    handleChange("fillingHourCalculationLimit", val)
                                }
                                endAdornment="h"
                                disabled={!isFillingHourCalculationOn}
                                className="field-width-normal"
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    );
};
